@import "variables";

html {
  font-size: 16px;
}

.option-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slideshow-container {
  width: 100%;
  height: 44vw;
  @include sm-up {
    height: 166px;
  }
  position: relative;
  .ss-el {
    position: absolute;
    transition: .3s;
    opacity: 0;
    z-index: 50;
    &.ss-active {
      opacity: 1;
      z-index: 51;
    }
  }
}

body {
  margin: 0;
  width: 100%;
  font-family: "Alegreya", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('images/cont-bg-light.png');
  background-repeat: repeat-y;
  background-color: #f2f2f2;
  background-position: center top;
  background-attachment: fixed;
  background-size: 110% auto;

  &.main-menu {
      background-image: url('images/sea_bg.jpg');
      background-position: center center;
      background-size: cover;
      @include md-up {
          .rope-left, .rope-right {
              display: none;
          }
      }
  }
  @include md-up {
    background-image: url('images/sea_bg.jpg');
    background-position: center center;
    background-size: cover;
  }
}
#root {
    .root-inside {
        height: 100%;
        min-height: 100%;
    }
}
.appContainer {
    height: 100%;
    min-height: 100%;
}


h1 {
  font-family: "OldShip";
  color: $nice-dark-blue;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 100;
  margin: 24px 10px 0;
  text-align: center;

  @include ntn-up {
      font-size:1.3rem;
  }

  @include sm-up {
      font-size: 1.8rem;
  }

  @include md-up {
    font-size: 2rem;
  }
}

.label {
    padding: 0 5px;
}

h4 {
  text-transform: uppercase;
  font-weight:bold;
  margin: .5rem 5px;
  text-align: center;
}

hr {
  height: 9px;
  background-image: url('images/divider.png');
  background-size: 100% 100%;
  background-position: top center;
  width:80%;
  margin: 0;
  border: none;
}

.error {
  color:$nice-red;
}

img {
    max-width: 100%;
    height: auto;
}

button.yar {
    display:block;
    margin: .5rem 0 28px;
    padding: 16px 10px 10px;
    background-color: $nice-blue;
    background-image: url('images/button-bg-title.jpg');
    background-position: center center;
    background-size: 330px auto;
    width: 90%;
    border-image-source: url('images/rope-container-250x80.png');
    border-width: 3px;
    border-image-slice: 3 fill;
    border-image-outset: 2px;
    font-family: "OldShip", serif;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight:bold;
    cursor: pointer;
    min-height: 75px;
    text-decoration: none;
    color: white;
    text-shadow: 2px 2px 2px #000000;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .3s;

    @include sm-up {
        width:326px;
        border-image-source: url('images/rope-container-330x80.png');
    }

    &:hover, &:active, &:visited {
        outline: none;
        background-image: url('images/button-bg-wood-hover.jpg');
    }
}

button.menu-option {
    display:block;
    min-width: 100px;
    margin: .5rem;
    padding: .5rem;
    background-color: $nice-blue;
    background-image: url('images/button-bg-wood.jpg');
    background-position: center center;
    background-size: 330px auto;
    border-image-source: url('images/rope-container-250x80.png');
    border-width: 3px;
    border-image-slice: 3 fill;
    border-image-outset: 2px;
    font-family: "Alegreya", sans-serif;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight:bold;
    cursor: pointer;
    min-height: 76px;
    text-decoration: none;
    color: white;
    text-shadow: 2px 2px 2px #000000;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .3s;
    width: 80%;

    &:hover, &:active, &:visited {
        outline: none;
        background-image: url('images/button-bg-wood-hover.jpg');
    }

    @include sm-up {
      width: 326px;
      padding: 5px 5px;
      border-image-source: url('images/rope-container-330x80.png');
    }

    &.title {
        font-size: 2rem;
        font-family: "OldShip", serif;
        font-weight: normal;
        background-image: url('images/button-bg-title.jpg');
        padding-top: .8rem;
        min-height: 80px;
        &:hover, &:active, &:visited {
            background-image: url('images/button-bg-wood-hover.jpg');
            outline: none;
        }
        @include sm-up {
          width: 350px;
          border-image-source: url('images/rope-container-330x80.png');
        }
        @include md-up {
            width: 394px;
            background-size: 400px auto;
            border-image-source: url('images/rope-container-398x80.png');
        }
    }
}

button.content-option {
  display:block;
  margin: .5rem;
  padding: .5rem;
  min-width: 100px;
  background-color: $nice-blue;
  background-image: url('images/button-bg.jpg');
  background-position: center center;
  background-size: 330px auto;
  border-image-source: url('images/rope-container-330x80.png');
  border-width: 3px;
  border-image-slice: 3 fill;
  border-image-outset: 2px;
  font-family: "Alegreya", sans-serif;
  font-size: 1.1rem;
  font-weight:bold;
  cursor: pointer;
  min-height: 80px;
  text-decoration: none;
  color: white;
  text-shadow: 2px 2px 2px #000000;
  box-sizing: border-box;
  box-shadow: inset 3px 3px 2px 0px rgba(0,0,0,.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .3s;
  width: 90%;

  &:hover, &:active, &:visited {
      background-image: url('images/button-bg-hover.jpg');
  }

  @include sm-up {
    width: 326px;
    padding: 5px 30px;
  }
}


button.option-option {
  display:block;
  margin: .5rem;
  padding: .5rem;
  min-width: 100px;
  background-color: $nice-blue;
  background-image: url('images/button-bg.jpg');
  background-position: center center;
  background-size: 330px auto;
  border-image-source: url('images/rope-container-265x54.png');
  border-width: 2px;
  border-image-slice: 3 fill;
  border-image-outset: 2px;
  font-family: "Alegreya", sans-serif;
  font-size: 1.1rem;
  font-weight:bold;
  cursor: pointer;
  min-height: 54px;
  text-decoration: none;
  color: white;
  text-shadow: 2px 2px 2px #000000;
  box-sizing: border-box;
  box-shadow: inset 3px 3px 2px 0px rgba(0,0,0,.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .3s;
  width: 90%;

  &:hover, &:active, &:visited {
      background-image: url('images/button-bg-hover.jpg');
  }

  @include sm-up {
    width: 261px;
    padding: 5px 30px;
  }
}


button.back {
  width: 48px;
  height: 32px;
  background-image: url('images/back.png');
  background-repeat: no-repeat;
  background-size: 48px 28px;
  background-position: center center;
  content: none;
  margin:0 8px 0 0; padding:0;
  background-color: transparent;
  font-size:0px;
  border:none;
  min-width:auto;
  cursor: pointer;
}

button.settings {
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-image: url('images/gear.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: none;
  margin:0; padding:0;
  background-color: transparent;
  font-size:0px;
  border:none;
  min-width:auto;
  display: none;
  @include ntn-up {
      display: block;
  }
}

.rope-left {
  display:none;
  position:fixed;
  left:0;
  top:0;
  width: 16px;
  height:100%;
  background-image: url('images/rope-left.png');
  background-size: 16px 1459px;
  z-index:100;
  transition: .3s;

  @include md-up {
    display:block;
    left: 50%;
    margin-left:-316px;
  }
}

.rope-right {
  display:none;
  position:fixed;
  right:-2px;
  top:0;
  width: 16px;
  height:100%;
  background-image: url('images/rope-right.png');
  z-index:100;
  background-size: 16px 1459px;
  transition: .3s;

  @include md-up {
    display:block;
    right: 50%;
    margin-right: -316px;
  }
}



#root {
  width:100%;

  & > div {
    width:100%;

    @include md-up {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.appContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;

  @include md-up {
    width: 612px;
    padding: 0 6px 0;
    background-image: url('images/cont-bg-light.png');
    background-attachment: fixed;
    background-position: top center;
    background-size: 660px 1347px;
    background-repeat: repeat-y;
  }

  &.naked {
      background-image: none;
  }
}

.screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  position: relative;

  @include md-up {
    width:100%;
  }
}

.top-runner {
  background-image: url('images/top-runner.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height: 64px;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px 8px;
  box-sizing:border-box;
  font-size: 16px;
  font-family: "OldShip", serif;
  .runner-title {
      padding-top: 14px;
      display: none;
      @include ntn-up {
          display: block;
      }
  }
  @include ntn-up {
      padding: 0 24px 8px;
  }

  div {
    color: white;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include md-up {
      font-size:24px;
    }
  }
}

.runner-buttons {
    margin-left: 10px;
}

.screen.main-menu {

  p {
    margin: 5px 6%;
  }
}

.main-menu-bottom {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.notification {
    width: 80%;
    box-sizing: border-box;
    font-weight: bold;
    font-style: italic;
    color: $nice-dark-blue;
    font-size: 1rem;
    padding: 15px;
    background-color: rgba(255, 255, 255, .5);
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include md-up {
        width: 420px;
    }
}

.play-now-btn {
  margin-top: 60px;
}

.game-logo {
  margin: 20px 0 20px;
  width: 80%;

  @include sm-up {
    width: 350px;
  }
}

.studio-logo-container {
  margin-top: 25px;
  text-align:center;
  display: block;
}

.studio-logo {
  width: 200px;
}

.cr-logo {
    width: 200px;
    margin-top: 30px;
}

.footnote {
  margin: 0 0 30px 0;
  font-size: .7rem;
  text-align:center;
}

.screen.scenario-selector {
  padding-top: 70px;

  ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      width: 100%;
      margin: 1rem 0;
      text-align: center;

      img {
        max-width: 90%;
        @include sm-up {
            max-width: 300px;
        }
      }
    }
  }
}

.screen.entry-display, .screen.narrative-text-choice {
  padding-top:70px;

  .setup-content {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    h1 {
      align-self: center;
    }

    h2 {
        font-size:1rem;
        line-height: 1.3rem;
      background-image: url('images/bluebanner.png');
      background-size: 100% 100%;
      color:white;
      font-family: "Alegreya", serif;
      font-weight: bold;
      margin: .4rem 0 .7rem;
      padding: 4px 15px;
      text-align: center;
      align-self: center;
      box-sizing: border-box;
      @include sm-up {
          width: 300px;
          font-size: 1.2rem;
          line-height: 1.6rem;
      }
    }


  }
  .entry-content {
    display:flex;
    flex-direction:column;
  }

}

.screen.options-screen {
  padding-top:60px;

  h2 {
    color:black;
    font-size: 1.1rem;
    font-family: "Alegreya", serif;
    font-weight: bold;
    margin: 40px 0 0;
    text-align: center;
    align-self: center;
    box-sizing: border-box;
    text-transform: uppercase;
  }

  .narrative-text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      select {
          margin-top: 12px;
      }
      div {
          padding: 12px;
          text-align: center;
      }
  }
}

.screen.language-screen {
  padding-top:60px;
}

.screen.about-info {
  padding-top:60px;

  p {
    padding:0 20px;
    text-align: center;
  }

  img {
    max-width:70%;
  }
}

.screen.check-tokens {
  padding-top:60px;

  p {
    padding:0 20px;
  }

  img {
    max-width:70%;
  }
}

.screen.variants {
  padding-top:60px;

  div.content-text {
    padding:0 20px;
  }

  img {
    max-width:70%;
  }

  li {
      margin-bottom:8px;
  }
}

.screen.history-screen {
  padding-top:60px;
}

.entry-history-display {
  display: flex;
  flex-direction:column;
  align-items:center;

  .history-option {
      margin: 15px;
  }
}

.screen.sand-timer {
  padding-top: 80px;


  .time-left {
    position: relative;
  }

  .seconds {
    font-size: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 33%;
    text-align: center;
  }

  .hourglass img {
    width: 160px;
  }

  .message {
    font-size: 2rem;
    color:$nice-red;

    .fw-icon {
      height: 1.6rem;
    }
  }

  & > button {
    font-size: 2rem;
    position:fixed;
    bottom: 0;
    z-index: 200;
  }

  @include tall {

    & > button {
      position: static;
    }
  }
}

.screen.play-menu {
  padding-top: 70px;
}

  input[type="number"] {
    height: 2.5rem;
    border: 1px solid black;
    font-size: 1rem;
    text-align: center;
    border-radius: 3px;
    max-width: 100%;
  }

.entry-form {
  display:flex;
  flex-direction: column;
  align-items: center;

  p {
    padding: 0;
    margin: .2rem;
    text-align: center;
  }



}

.entry-content.content-text {
  box-sizing: border-box;
  padding: .5rem 25px;
  width:100%;
  display: flex;
  flex-direction: column;

  h2 {
    margin: .3rem 0;
    padding: 0;
    font-family: "OldShip", serif;
    font-size:1rem;
    line-height: 1.3rem;
    font-weight:100;

    @include sm-up {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

  }
}


.fw-icon {
  height: .8rem;
  width: auto;
}

.large {
  .fw-icon {
    height:1.8rem;

    @include sm-up {
      height: 2.5rem;
    }
  }
}

.content-text {

  p {
    text-align:left;
  }

  .br-line {
    margin:0;
    height:12px;
  }

  .large {
    font-size: 2.2rem;
    width: 100%;

    @include sm-up {
      font-size: 4rem;
    }

    .br-line {
      margin:0;
      height:0;
    }

    ul {
      display:flex;
      flex-direction:row;
      justify-content: space-around;
      margin:0;
      padding:0;
      list-style:none;

      li{
        margin:0;
        padding:0;
      }
    }
  }
}

.audio-player-container {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .audio-player-error {
        color: $nice-red;
        font-weight: bold;
        text-align: center;
        margin: -10px 0 10px;
    }
}

.audio-player {
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-sizing: border-box;
  align-items: center;
  align-self: center;
  margin-bottom: 10px;
  background-image: url('images/plank.png');
  background-size: 100% 100%;
  width: 80%;
  height: 72px;
  @include sm-up {
      width: 301px;
      height: 72px;
      padding: 0 50px 0;
  }
  .ui {
    opacity: .6;
    cursor: default;
    color: gray;
    width: 49px;
    height: 55px;
    background-size: 100% 100%;
    &.active {
      opacity: 1;
      cursor: pointer;
      color: white;
    }
    &.rw {
        background-image: url('images/audio-rw.png');
        transition: .3s;
        &.active:hover {
            background-image: url('images/audio-rw-hover.png');
        }
    }
    &.ff {
        background-image: url('images/audio-ff.png');
        transition: .3s;
        &.active:hover {
            background-image: url('images/audio-ff-hover.png');
        }
    }
    &.play {
        background-image: url('images/audio-play.png');
        &.active:hover {
            background-image: url('images/audio-play-hover.png');
        }
    }
    &.pause {
        background-image: url('images/audio-pause.png');
        &.active:hover {
            background-image: url('images/audio-pause-hover.png');
        }
    }
  }
}

.scenario-option-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    margin-top: 12px;

    @include ntn-up {
        flex-direction: row;
        justify-content: space-evenly;
    }

    @include sm-up {
        padding: 0 30px;
    }

    @include md-up {
        padding: 0 40px;
    }

    button.scenario-option {
        border: none;
        font-family: "Alegreya", serif;
        color: $nice-dark-blue;
        background-color: transparent;
        transition: .3s;
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1rem;
        font-weight: bold;
        cursor: pointer;
        width:100px;
        background-size: 100px auto;
        text-align: center;
        background-position: center top;
        padding-top: 102px;
        margin-bottom: 15px;
        background-repeat: no-repeat;
        outline: none;

        @include ntn-up {
            width: auto;
            min-width: 80px;
            padding-top: 90px;
            background-size: 80px auto;
            margin-bottom: 0;
        }

        @include sm-up {
            width: auto;
            min-width: 100px;
            padding-top: 110px;
            background-size: 100px auto;

        }

        &.scenario-option-setup {
            background-image: url('./images/scenario-setup.png');
            &:hover {
                background-image: url('./images/scenario-setup-hover.png');
            }
        }

        &.scenario-option-timer {
            background-image: url('./images/scenario-timer.png');
            &:hover {
                background-image: url('./images/scenario-timer-hover.png');
            }
        }

        &.scenario-option-history {
            background-image: url('./images/scenario-history.png');
            &:hover {
                background-image: url('./images/scenario-history-hover.png');
            }
        }

    }
}

body .appContainer.korean {
    font-family: HeirOfLight;
}

.appContainer.korean {
    button.menu-option.title {
        font-family: HeirOfLight;
        padding-top:0;
    }
    button.menu-option {
        font-family: HeirOfLight;
    }
    h1 {
        font-family: HeirOfLight;
    }
    button.yar {
        font-family: HeirOfLight;
    }
    .scenario-option-container button.scenario-option {
        font-family: HeirOfLight;
    }
    button.content-option {
        font-family: HeirOfLight;
    }
    .entry-content.content-text h2 {
        font-family: HeirOfLight;
    }
    .top-runner {
        font-family: HeirOfLight;
    }

}

.play-objective {
    width: 50%;
    height: auto;
}
