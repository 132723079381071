// breakpoints
$screen-not-tiny: 240px;
$screen-sm: 425px;
$screen-md: 640px;
$screen-lg: 1024px;
$banner-width: 1100px;
$screen-xlg: 1200px;

// media queries
$not-tiny-width: 'screen and (min-width: ' $screen-not-tiny ')';
$small-min-width: 'screen and (min-width: ' $screen-sm ')';
$medium-min-width: 'screen and (min-width: ' $screen-md ')';
$large-min-width: 'screen and (min-width: ' $screen-lg ')';
$banner-min-width: 'screen and (min-width: ' $banner-width ')';
$x-large-min-width: 'screen and (min-width: ' $screen-xlg ')';

$screen-tall: 'screen and (min-height: 600px)';

$nice-dark-blue: #104673;
$nice-blue: #457ba2;
$nice-light-blue: #b4cfe5;
$nice-teal: #81b1a0;
$nice-red: #d35e5a;

// mixins
/* stylelint-disable */

// transition animation
@mixin transition($type, $time, $easing) {
  transition: $type ($time + s) $easing;
}

// media queries

@mixin tall {
  @media only #{$screen-tall} {
    @content;
  }
}

@mixin ntn-up {
  @media only #{$not-tiny-width} {
    @content;
  }
}

@mixin sm-up {
  @media only #{$small-min-width} {
    @content;
  }
}

@mixin md-up {
  @media only #{$medium-min-width} {
    @content;
  }
}

@mixin lg-up {
  @media only #{$large-min-width} {
    @content;
  }
}

@mixin banner-width {
  @media only #{$banner-min-width} {
    @content;
  }
}

@mixin xlg-up {
    @media only #{$x-large-min-width} {
        @content;
    }
}

@font-face {
    font-family: OldShip;
    src: url('../fonts/OldShip.otf');
}

@font-face {
    font-family: Alegreya;
    src: url('../fonts/Alegreya-Regular.ttf');
}

@font-face {
    font-family: Alegreya;
    font-weight: bold;
    src: url('../fonts/Alegreya-Bold.ttf');
}

@font-face {
    font-family: Alegreya;
    font-style: italic;
    src: url('../fonts/Alegreya-Italic.ttf');
}

@font-face {
    font-family: HeirOfLight;
    src: url('../fonts/HeirofLightOTFRegular.otf');
}

@font-face {
    font-family: HeirOfLight;
    font-weight: bold;
    src: url('../fonts/HeirofLightOTFBold.otf');
}

@font-face {
    font-family: Alegreya;
    font-style: italic;
    font-weight: bold;
    src: url('../fonts/Alegreya-BoldItalic.ttf');
}
